import React from 'react';
import { Layout, SEO } from 'components/common';
import { Digitezaleahnheilkunde } from 'components/landing/Digitezaleahnheilkunde';

export default () => (
  <Layout>
    <SEO />
    <Digitezaleahnheilkunde/>
  </Layout>
);
